import {
  Grid,
  GridItem,
  Heading,
  Stack,
  TextField,
  TextFieldButton,
  TextFieldIconButton,
} from '@carvertical/ui';
import { FormProvider, useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormField } from 'components/common/ui/forms';
import { getBasePath } from 'utils/locale';
import { useRouteData } from 'context/RouteDataProvider';
import { BLOG_ROOT_PATH } from '../../constants';
import { type SearchBlockFormFields, searchBlockFormSchema } from './schemas';
import styles from './SearchBlock.module.scss';

const SearchBlock = () => {
  const { t } = useTranslation(['content', 'common']);
  const { locale } = useRouteData();
  const { push, query } = useRouter();
  const form = useForm<SearchBlockFormFields>({
    defaultValues: { term: '' },
    resolver: zodResolver(searchBlockFormSchema),
  });

  const { formState, handleSubmit, reset } = form;

  const basePath = getBasePath(locale);

  useEffect(() => {
    reset();
  }, [query, reset]);

  const submit = ({ term }: SearchBlockFormFields) =>
    push(
      {
        pathname: `${basePath}${BLOG_ROOT_PATH}/search`,
        query: { term },
      },
      undefined,
    );

  const loading = formState.isValid && formState.isSubmitting;

  const Field = FormField<SearchBlockFormFields>;

  return (
    <section className={styles.root}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(submit)}>
          <Grid>
            <GridItem
              span={{ mobileUp: 12, tabletPortraitUp: 8, desktopUp: 6 }}
              start={{ mobileUp: 1, tabletPortraitUp: 3, desktopUp: 4 }}
            >
              <Stack gap={5} crossAxisAlign="center">
                <Heading variant="xs" as="h2">
                  {t('searchBlog')}
                </Heading>

                <Field name="term">
                  <TextField
                    className={styles.textField}
                    label={t('searchArticles')}
                    placeholder={t('searchArticles')}
                    labelHidden
                    autoFocus
                    disabled={loading}
                    fullWidth
                    type="search"
                    trailingAddon={
                      <Stack type="horizontal" crossAxisAlign="center">
                        <TextFieldIconButton
                          icon="close"
                          label={t('common:general.clearInputLabel')}
                          onClick={() => reset()}
                        />

                        <TextFieldButton type="submit" variant="blue" loading={loading}>
                          {t('search')}
                        </TextFieldButton>
                      </Stack>
                    }
                  />
                </Field>
              </Stack>
            </GridItem>
          </Grid>
        </form>
      </FormProvider>
    </section>
  );
};

export { SearchBlock };
